const routes = {
  home: '/',
  checkRouters: '/:id',
  dashboard: '/dashboard',
  dashboardLogin: '/login',
  dashboardUser: '/user',
  dashboardUsers: '/users',
  dashboardDsUsers: '/dsusers',
  dashboardChangePass: '/changepass',
  //Danh Mục
  dashboardDiadiem: '/diadiem',
  dashboardKQH: '/khuquyhoach',
  dashboardQH: '/quyhoach',
  dashboardPhaply: '/phaply',
  dashboardHuongbds: '/huongbds',
  dashboardVitri: '/vitri',
  dashboardLoaiBDS: '/loaibds',
  dashboardTiennghi: '/tiennghi',
  dashboardDactrung: '/dactrung',
  //BĐS
  dashboardAddBDS: '/addbds',
  dashboardDsBDS: '/dsbds',
  dashboardDsDuyetBDS: '/dsduyetbds',
  dashboardBDSDetail: '/bds/:id',
};

export default routes;
