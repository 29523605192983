import {
  BellAlertIcon,
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { Alert, Button, Card, Checkbox, Chip, IconButton, Input, Textarea, Tooltip } from '@material-tailwind/react';
import { useState, useEffect, useRef } from 'react';
import MapComponent from '../../../components/MapComponent';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { convertNumberToWords, getCookie, numberFormatTextShorten } from '../../../utils/helper';
import * as imgDF from '../../../assets/images/index';
import {
  listAllDactrung,
  listAllHuongBds,
  listAllKqhs,
  listAllLoaibds,
  listAllPhaply,
  listAllQhs,
  listAllStreets,
  listAllTiennghi,
  listAllVitri,
} from '../../../services/apiCategory';
import { API_URL } from '../../../utils/urls';
import { addBds, getIdBds, updateBds } from '../../../services/apiBds';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function AddBds() {
  const inputFile = useRef(null);
  const inputFileSo = useRef(null);
  const auth = getCookie('token');
  const [open, setOpen] = useState(0);
  const [checkInfo, setCheckInfo] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [streetName, setStreetName] = useState(
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d124928.46940428336!2d108.36832152803531!3d11.904066867762978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317112fef20988b1%3A0xad5f228b672bf930!2zVHAuIMSQw6AgTOG6oXQsIEzDom0gxJDhu5NuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1727619154488!5m2!1svi!2s',
  );
  const [arrPhone, setArrPhone] = useState(['']);
  const [arrSoTo, setArrSoTo] = useState(['']);
  const [arrSoThua, setArrSoThua] = useState(['']);
  const [coords, setCoords] = useState();
  const [images, setImages] = useState([]);
  const [imageURLS, setImageURLs] = useState([]);
  const [imagesSo, setImagesSo] = useState([]);
  const [imageURLSSo, setImageURLsSo] = useState([]);
  const [imageURLsUpdate, setImageURLsUpdate] = useState([]);
  const [imageURLsSoUpdate, setImageURLsSoUpdate] = useState([]);
  const [imageRemove, setImageRemove] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');
  const [arrBDS, setArrBDS] = useState({
    id_user: auth?.id,
    username: auth?.username,
    masp: '',
    tieude: '',
    toado: streetName,
    sonha: '',
    duong: '',
    huyen: '',
    xa: '',
    tinh: 'Lâm Đồng',
    khuqh: '',
    soto: '',
    sothua: '',
    sdtchudat: '',
    isbandat: '',
    ischothue: '',
    ishanche: 0,
    isdocquyen: 0,
    rongduong: '',
    quyhoach: '',
    phaply: '',
    huongbds: '',
    loaivitri: '',
    loaibds: '',
    tiennghi: '',
    dactrung: '',
    notenoibo: '',
    notekhach: '',
    dientich: '',
    dientichdatnn: '',
    dientichsan: '',
    ngang: '',
    nganghau: '',
    dai: '',
    sotang: '',
    sophong: '',
    sowc: '',
    giathue: '',
    gia: '',
    anhto: '',
    anhthua: '',
    status: auth?.permission === 1 || auth?.permission === 2 ? '1' : '3',
  });

  const [arrOptionSelect, setArrOptionSelect] = useState({});
  const [arrOptionSelectSearch, setArrOptionSelectSearch] = useState({});
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const idBds = urlParams.get('u');

  useEffect(() => {
    document.title = 'Bài viết bất động sản';
  }, []);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const fn = async (e) => {
    if (e.key === 'Enter') {
      const result = await geocodeByAddress(streetName);
      const lngLat = await getLatLng(result[0]);
      setCoords(lngLat);
    }
  };

  const handleSearchOptionSelect = (key, val, keysearch) => {
    if (val) {
      const results = arrOptionSelect[key].filter((item) => item[keysearch].toLowerCase().includes(val.toLowerCase()));
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: results });
    } else {
      setArrOptionSelectSearch({ ...arrOptionSelect, [key]: arrOptionSelect[key] });
    }
  };

  const getStreets = async () => {
    const resultAPI = await listAllStreets(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, streets: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, streets: resultAPI });
    }
  };

  const getKQH = async () => {
    const resultAPI = await listAllKqhs(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, kqhs: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, kqhs: resultAPI });
    }
  };
  const getQH = async () => {
    const resultAPI = await listAllQhs(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, qhs: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, qhs: resultAPI });
    }
  };
  const getPhaply = async () => {
    const resultAPI = await listAllPhaply(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, phaply: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, phaply: resultAPI });
    }
  };
  const getHuongBds = async () => {
    const resultAPI = await listAllHuongBds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, huongbds: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, huongbds: resultAPI });
    }
  };
  const getLoaiVitri = async () => {
    const resultAPI = await listAllVitri(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaivitri: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaivitri: resultAPI });
    }
  };
  const getLoaiBDS = async () => {
    const resultAPI = await listAllLoaibds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaibds: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, loaibds: resultAPI });
    }
  };
  const getTiennghi = async () => {
    const resultAPI = await listAllTiennghi(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, tiennghi: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, tiennghi: resultAPI });
    }
  };
  const getDactrung = async () => {
    const resultAPI = await listAllDactrung(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, dactrung: resultAPI });
      setArrOptionSelectSearch({ ...arrOptionSelect, dactrung: resultAPI });
    }
  };

  const uniqueData = (data, key) => {
    return data.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
  };

  const removeDataBds = (data, key, index) => {
    const updatedData = data.filter((item, i) => i !== index);
    setArrBDS({
      ...arrBDS,
      [key]: updatedData.join(','),
    });
  };

  const checkText = (data1, data2) => {
    const textArray1 = data1.split(','); // Split data1 into an array
    const textArray2 = data2.split(','); // Split data2 into an array

    const isMatch = textArray1.some((text) => textArray2.includes(text));

    return isMatch;
  };

  const reset = () => {
    setArrPhone(['']);
    setArrSoThua(['']);
    setArrSoTo(['']);
    setImages([]);
    setImageURLs([]);
    setImagesSo([]);
    setImageURLsSo([]);
    setImageURLsUpdate([]);
    setImageURLsSoUpdate([]);
    setImageRemove([]);
    setArrBDS({
      id_user: auth?.id,
      username: auth?.username,
      masp: '',
      tieude: '',
      toado: streetName,
      sonha: '',
      duong: '',
      huyen: '',
      xa: '',
      tinh: 'Lâm Đồng',
      khuqh: '',
      soto: '',
      sothua: '',
      sdtchudat: '',
      isbandat: 0,
      ischothue: 0,
      ishanche: 0,
      isdocquyen: 0,
      rongduong: '',
      quyhoach: '',
      phaply: '',
      huongbds: '',
      loaivitri: '',
      loaibds: '',
      tiennghi: '',
      dactrung: '',
      notenoibo: '',
      notekhach: '',
      dientich: '',
      dientichdatnn: '',
      dientichsan: '',
      ngang: '',
      nganghau: '',
      dai: '',
      sotang: '',
      sophong: '',
      sowc: '',
      giathue: '',
      gia: '',
      anhto: '',
      anhthua: '',
      status: '1',
    });
  };

  const getBdsId = async (id) => {
    const resultID = await getIdBds(id);
    if (resultID.length > 0) {
      setCheckInfo(true);
      setArrBDS({
        id: resultID[0].id,
        id_user: auth?.id,
        username: auth?.username,
        masp: resultID[0].masp,
        tieude: resultID[0].tieude,
        toado: resultID[0].toado,
        sonha: resultID[0].sonha,
        duong: resultID[0].duong,
        huyen: resultID[0].huyen,
        xa: resultID[0].xa,
        tinh: resultID[0].tinh,
        khuqh: resultID[0].khuqh,
        soto: resultID[0].soto,
        sothua: resultID[0].sothua,
        sdtchudat: resultID[0].sdtchudat,
        isbandat: resultID[0].isbandat,
        ischothue: resultID[0].ischothue,
        rongduong: resultID[0].rongduong,
        quyhoach: resultID[0].quyhoach,
        phaply: resultID[0].phaply,
        huongbds: resultID[0].huongbds,
        loaivitri: resultID[0].loaivitri,
        loaibds: resultID[0].loaibds,
        tiennghi: resultID[0].tiennghi,
        dactrung: resultID[0].dactrung,
        notenoibo: resultID[0].notenoibo,
        notekhach: resultID[0].notekhach,
        dientich: resultID[0].dientich,
        dientichdatnn: resultID[0].dientichdatnn,
        dientichsan: resultID[0].dientichsan,
        ngang: resultID[0].ngang,
        nganghau: resultID[0].nganghau,
        dai: resultID[0].dai,
        sotang: resultID[0].sotang,
        sophong: resultID[0].sophong,
        sowc: resultID[0].sowc,
        giathue: resultID[0].giathue,
        gia: resultID[0].gia,
        anhto: resultID[0].anhto,
        anhthua: resultID[0].anhthua,
        status: '1',
      });
      setArrPhone(resultID[0].sdtchudat?.split(','));
      setArrSoTo(resultID[0].soto?.split(','));
      setArrSoThua(resultID[0].sothua?.split(','));
      setImageURLsUpdate(resultID[0].anhto?.split(','));
      setImageURLsSoUpdate(resultID[0].anhthua?.split(','));
    } else {
      setCheckInfo(false);
    }
  };

  useEffect(() => {
    getBdsId(idBds);
  }, [idBds]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const subUpdateDefault = async (e) => {
    e.preventDefault();
    if (arrBDS.duong === '') {
      setWarningMessage('Bạn chưa nhập tên Đường');
    } else if (arrBDS.huongbds === '') {
      setWarningMessage('Bạn chưa nhập Hướng BĐS');
    } else if (arrBDS.loaivitri === '') {
      setWarningMessage('Bạn chưa nhập Vị Trí');
    } else if (arrBDS.loaibds === '') {
      setWarningMessage('Bạn chưa nhập Loại BĐS');
    } else {
      setLoading(true);
      setIsDisabled(true);
      const formData = new FormData();
      let arrAnhTo = [...imageURLsUpdate];
      let arrAnhThua = [...imageURLsSoUpdate];
      images.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_img_' + Date.now() + '.' + extension;
        arrAnhTo.push(timestamp);
        formData.append('image', image, timestamp);
      });
      imagesSo.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_to_' + Date.now() + '.' + extension;
        arrAnhThua.push(timestamp);
        formData.append('image', image, timestamp);
      });
      let arrBDSSV = { ...arrBDS, anhto: arrAnhTo.join(','), anhthua: arrAnhThua.join(',') };
      formData.append('data', JSON.stringify(arrBDSSV));
      formData.append('imgremove', JSON.stringify(imageRemove));
      const upload = await updateBds(formData);
      if (upload.message === 'OK') {
        reset();
        navigate('/dsbds');
      } else {
        setLoading(false);
        setIsDisabled(false);
      }
    }
  };
  const subAddDefault = async (e) => {
    e.preventDefault();
    if (arrBDS.duong === '') {
      setWarningMessage('Bạn chưa nhập tên Đường');
    } else if (arrBDS.huongbds === '') {
      setWarningMessage('Bạn chưa nhập Hướng BĐS');
    } else if (arrBDS.loaivitri === '') {
      setWarningMessage('Bạn chưa nhập Vị Trí');
    } else if (arrBDS.loaibds === '') {
      setWarningMessage('Bạn chưa nhập Loại BĐS');
    } else {
      setLoading(true);
      setIsDisabled(true);
      const formData = new FormData();
      let arrAnhTo = [];
      let arrAnhThua = [];
      images.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_img_' + Date.now() + '.' + extension;
        arrAnhTo.push(timestamp);
        formData.append('image', image, timestamp);
      });
      imagesSo.forEach((image, i) => {
        const extension = image.name.split('.').pop();
        let timestamp = i + '_to_' + Date.now() + '.' + extension;
        arrAnhThua.push(timestamp);
        formData.append('image', image, timestamp);
      });
      let arrBDSSV = { ...arrBDS, anhto: arrAnhTo.join(','), anhthua: arrAnhThua.join(',') };
      formData.append('data', JSON.stringify(arrBDSSV));
      const upload = await addBds(formData);
      if (upload.message === 'OK') {
        scrollToTop();
        reset();
        setLoading(false);
        setIsDisabled(false);
        setMessage('Thành Công');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setLoading(false);
        setIsDisabled(false);
        setMessage('Thất Bại');
      }
      setWarningMessage('');
    }
  };

  const changePhone = (e, i) => {
    let value = e.target.value;
    let updatedData = arrPhone.map((item, index) => {
      if (index === i) {
        return value;
      }
      return item;
    });
    setArrPhone(updatedData);
    setArrBDS({ ...arrBDS, sdtchudat: updatedData.join(',') });
  };
  const changeSoTo = (e, i) => {
    let value = e.target.value;
    let updatedData = arrSoTo.map((item, index) => {
      if (index === i) {
        return value;
      }
      return item;
    });
    setArrSoTo(updatedData);
    setArrBDS({ ...arrBDS, soto: updatedData.join(',') });
  };
  const changeSoThua = (e, i) => {
    let value = e.target.value;
    let updatedData = arrSoThua.map((item, index) => {
      if (index === i) {
        return value;
      }
      return item;
    });
    setArrSoThua(updatedData);
    setArrBDS({ ...arrBDS, sothua: updatedData.join(',') });
  };

  const removePhone = (index) => {
    const arrRemove = arrPhone.filter((_, i) => i !== index) || [];
    setArrPhone(arrRemove);
  };
  const removeToThua = (index) => {
    const arrRemoveThua = arrSoThua.filter((_, i) => i !== index) || [];
    setArrSoThua(arrRemoveThua);
    const arrRemoveTo = arrSoTo.filter((_, i) => i !== index) || [];
    setArrSoTo(arrRemoveTo);
  };

  // HÌNH ẢNH
  const onButtonClickFileImage = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const onFilechange = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    if (images.length + imageURLsUpdate.length + e.target.files.length > 10) {
      alert('Bạn chỉ được thêm tối đa 10 ảnh');
      return;
    }
    [...e.target.files].forEach((file) => {
      if (file.type.startsWith('image/')) {
        tempArr.push(URL.createObjectURL(file));
        tempFileArr.push(file);
      } else {
        alert('Bạn không được thêm video hoặc tập tin');
        return;
      }
    });
    setImageURLs([...imageURLS, ...tempArr]);
    setImages([...images, ...tempFileArr]);
  };

  // xoa ảnh
  const removeImage = (index) => {
    const arrRemove = imageURLS.filter((_, i) => i !== index) || [];
    const arrRemoveFile = images.filter((_, i) => i !== index) || [];
    setImageURLs(arrRemove);
    setImages(arrRemoveFile);
  };
  const removeImageUpdate = (index) => {
    const arrRemove = imageURLsUpdate.filter((_, i) => i !== index) || [];
    const idRemove = imageURLsUpdate.filter((_, i) => i === index) || [];
    setImageRemove([...imageRemove, ...idRemove]);
    setImageURLsUpdate(arrRemove);
  };

  // HÌNH ẢNH SỔ
  const onButtonClickFileImageSo = () => {
    // `current` points to the mounted file input element
    inputFileSo.current.click();
  };

  const onFilechangeSo = (e) => {
    let tempArr = [];
    let tempFileArr = [];
    if (imagesSo.length + imageURLsSoUpdate.length + e.target.files.length > 5) {
      alert('Bạn chỉ được thêm tối đa 5 ảnh hoặc tập tin');
      return;
    }
    [...e.target.files].forEach((file) => {
      if (file.type.startsWith('image/')) {
        tempArr.push(URL.createObjectURL(file));
        tempFileArr.push(file);
      } else if (file.type.startsWith('video/')) {
        alert('Bạn không được thêm video');
        return;
      } else {
        tempArr.push('');
        tempFileArr.push(file);
      }
    });
    setImageURLsSo([...imageURLSSo, ...tempArr]);
    setImagesSo([...imagesSo, ...tempFileArr]);
  };

  // xoa ảnh
  const removeImageSo = (index) => {
    const arrRemove = imageURLSSo.filter((_, i) => i !== index) || [];
    const arrRemoveFile = imagesSo.filter((_, i) => i !== index) || [];
    setImageURLsSo(arrRemove);
    setImagesSo(arrRemoveFile);
  };

  const removeImageSoUpdate = (index) => {
    const arrRemove = imageURLsSoUpdate.filter((_, i) => i !== index) || [];
    const idRemove = imageURLsSoUpdate.filter((_, i) => i === index) || [];
    setImageRemove([...imageRemove, ...idRemove]);
    setImageURLsSoUpdate(arrRemove);
  };

  const typeImg = (str) => {
    const lastIndex = str.lastIndexOf('.');
    const result = str.substring(lastIndex + 1);
    if (result === 'png' || result === 'jpg' || result === 'jpeg') {
      return true;
    } else {
      return false;
    }
  };

  const extractSrcFromIframe = (text) => {
    const regex = /<iframe.*?src="(.*?)".*?>/;
    const matches = text.match(regex);
    if (matches && matches.length > 1) {
      const src = matches[1];
      return src;
    }
    return null;
  };

  const getSrcGGMap = (text) => {
    const texts = extractSrcFromIframe(text);
    return texts;
  };

  return (
    <div className="grid grid-cols-1">
      {message === 'Thành Công' && (
        <Alert
          icon={<BellAlertIcon className="h-5 w-5" />}
          className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946] flex items-center"
        >
          <b>THÊM TIN THÀNH CÔNG</b>
        </Alert>
      )}
      <div>
        <Card className="p-2">
          <h3 className="font-bold mb-2">THÊM BÀI VIẾT BẤT ĐỘNG SẢN</h3>
          <Alert
            color={message === 'Thành Công' ? 'green' : 'red'}
            className="mb-2"
            open={openAlert}
            onClose={() => setOpenAlert(false)}
          >
            {message === 'Thành Công' ? 'Cập Nhật Thành Công' : 'Cập Nhật Thất Bại'}
          </Alert>
          <div className="grid gap-4">
            <Input
              value={arrBDS?.tieude}
              placeholder="Thêm Tiêu Đề"
              required
              className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              onChange={(e) => {
                setArrBDS({ ...arrBDS, tieude: e.target.value });
              }}
            />
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 p-2 border-gray-400">TỌA ĐỘ GOOGLE MAP</div>
              <div className="grid gap-2 p-3">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Dán mã nhúng Google Map</b>{' '}
                    <a href={imgDF.default.hdgooglemap} className="text-blue-600 cursor-pointer" target="_blank">
                      (Xem hướng dẫn)
                    </a>
                  </label>

                  <Input
                    // value={arrBDS?.toado}
                    placeholder="Dán mã nhúng google map "
                    className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    onChange={(e) => {
                      setArrBDS({ ...arrBDS, toado: getSrcGGMap(e.target.value) });
                    }}
                    onKeyPress={fn}
                  />
                  <i className="text-xs text-gray-600">Copy mã nhúng google tại nút chia sẻ</i>
                </div>
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Bản đồ vị trí</b>
                  </label>
                  <iframe
                    src={arrBDS?.toado}
                    width="100%"
                    height="450"
                    // style="border:0;"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>

            {/* Địa chỉ BĐS - Nhập giống đúng như trên Sổ */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">Địa chỉ BĐS - Nhập giống đúng như trên Sổ</div>
              <div className="grid lg:grid-rows-2 lg:grid-flow-col sm:grid-cols-1 gap-4 p-3">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Số nhà (nếu có)</b>
                  </label>
                  <Input
                    value={arrBDS?.sonha}
                    placeholder="VD: 15A-17/1-3"
                    className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    onChange={(e) => {
                      setArrBDS({ ...arrBDS, sonha: e.target.value });
                    }}
                  />
                </div>

                {/* Quận, huyện, TP thuộc Tỉnh */}
                <div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>
                          Quận, huyện, TP thuộc Tỉnh <b className="text-red-500">*</b>
                        </b>
                      </label>

                      <div className="relative">
                        <button
                          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                          onClick={() => {
                            handleOpen(1);
                            getStreets();
                          }}
                        >
                          <span className="mr-2">
                            {arrBDS && arrBDS.huyen ? arrBDS.huyen : 'Chọn Quận, huyện, TP thuộc Tỉnh'}
                          </span>

                          {open === 1 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                        </button>
                        {open === 1 && (
                          <div
                            className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                          >
                            <Input
                              // value={}
                              className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                              labelProps={{
                                className: 'hidden',
                              }}
                              onChange={(e) => handleSearchOptionSelect('streets', e.target.value, 'huyen')}
                            />

                            <div className="pl-1">
                              <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                            </div>
                            {arrOptionSelectSearch?.streets &&
                              uniqueData(arrOptionSelectSearch?.streets, 'huyen')?.map((row, index) => (
                                <div
                                  className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                  key={index}
                                  onClick={() => {
                                    setArrBDS({ ...arrBDS, huyen: row?.huyen, duong: '', xa: '' });
                                    handleOpen(0);
                                    handleSearchOptionSelect('streets', row?.huyen, 'huyen');
                                  }}
                                >
                                  {row?.huyen},{row?.tinh}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="flex items-end gap-1 text-sm mb-1">
                        <b>
                          Phường, xã <b className="text-red-500">*</b>
                        </b>
                      </label>

                      <div className="relative">
                        <button
                          className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                          onClick={() => handleOpen(2)}
                        >
                          <span className="mr-2"> {arrBDS && arrBDS.xa ? arrBDS.xa : 'Chọn Phường, xã'}</span>
                          {open === 2 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                        </button>
                        {open === 2 && (
                          <div
                            className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                          >
                            <Input
                              // value={}
                              className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                              labelProps={{
                                className: 'hidden',
                              }}
                              onChange={(e) => handleSearchOptionSelect('streets', e.target.value, 'xa')}
                            />

                            <div className="pl-1">
                              <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                            </div>
                            {arrOptionSelectSearch?.streets &&
                              uniqueData(arrOptionSelectSearch?.streets, 'xa')?.map((row, index) => (
                                <div
                                  className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                  key={index}
                                  onClick={() => {
                                    setArrBDS({ ...arrBDS, xa: row?.xa });
                                    handleOpen(0);
                                    handleSearchOptionSelect('streets', row?.xa, 'xa');
                                  }}
                                >
                                  {row?.xa},{row?.huyen}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>
                      Tên Đường <b className="text-red-500">*</b>
                    </b>
                  </label>

                  <div className="relative">
                    <button
                      className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                      onClick={() => {
                        handleOpen(3);
                      }}
                    >
                      <span className="mr-2">
                        {arrBDS && arrBDS.duong ? arrBDS.duong : 'Nhập tên đường của đúng Quận/Huyện'}
                      </span>
                      {open === 3 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                    </button>
                    {open === 3 && (
                      <div
                        className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                      >
                        <Input
                          // value={}
                          placeholder="Nhập tên đường"
                          className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          onChange={(e) => handleSearchOptionSelect('streets', e.target.value, 'duong')}
                        />

                        <div className="pl-1">
                          <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                        </div>
                        {arrOptionSelectSearch?.streets &&
                          arrOptionSelectSearch?.streets.map((row, index) => (
                            <div
                              className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                              key={index}
                              onClick={() => {
                                setArrBDS({ ...arrBDS, duong: row?.duong });
                                handleOpen(0);
                              }}
                            >
                              {row?.duong},{row?.xa}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Số tờ </b>
                    <Tooltip content="Nhập đúng số tờ trong sổ" placement="right">
                      <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                    </Tooltip>
                  </label>

                  {arrSoTo?.map((row, i) => (
                    <div key={i} className="mb-1 flex">
                      {i !== 0 && (
                        <IconButton variant="text">
                          <TrashIcon className="h-5 w-5 text-red-500" onClick={() => removeToThua(i)} />
                        </IconButton>
                      )}
                      <Input
                        value={row}
                        placeholder="vd: 245"
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => changeSoTo(e, i)}
                      />
                    </div>
                  ))}
                </div>

                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Khu quy hoạch Đà lạt (nếu có)</b>
                  </label>

                  <div className="relative">
                    <button
                      className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                      onClick={() => {
                        handleOpen(4);
                        getKQH();
                      }}
                    >
                      <span className="mr-2"> {arrBDS && arrBDS.khuqh ? arrBDS.khuqh : 'Chọn KQH nếu có ...'}</span>
                      {open === 4 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                    </button>
                    {open === 4 && (
                      <div
                        className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                      >
                        <Input
                          // value={''}

                          className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          onChange={(e) => handleSearchOptionSelect('kqhs', e.target.value, 'name')}
                        />

                        <div className="pl-1">
                          <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                        </div>
                        {arrOptionSelectSearch?.kqhs &&
                          arrOptionSelectSearch?.kqhs.map((row, index) => (
                            <div
                              className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                              key={index}
                              onClick={() => {
                                setArrBDS({ ...arrBDS, khuqh: row?.name });
                                handleOpen(0);
                              }}
                            >
                              {row?.name}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Số thửa </b>
                    <Tooltip content="Nhập đúng số thửa trong sổ" placement="right">
                      <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                    </Tooltip>
                  </label>

                  {arrSoThua?.map((row, i) => (
                    <div key={i} className="mb-1">
                      <Input
                        value={row}
                        placeholder="vd: 689"
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => changeSoThua(e, i)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-2 grid grid-cols-1">
                <div className="flex justify-end">
                  <Button
                    size="sm"
                    color="blue"
                    className="normal-case"
                    onClick={() => {
                      setArrSoTo([...arrSoTo, '']);
                      setArrSoThua([...arrSoThua, '']);
                    }}
                  >
                    Thêm Tờ/Thửa
                  </Button>
                </div>
              </div>
            </div>

            {/* SỐ ĐIỆN THOẠI CỦA CHỦ */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">Thông tin chủ</div>
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-2 p-3">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Nhập Số điện thoại kèm theo tên Chủ</b>
                    <Tooltip content="..." placement="right">
                      <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                    </Tooltip>
                  </label>
                  {arrPhone?.map((row, i) => (
                    <div key={i} className="mb-1 flex">
                      <Input
                        value={row}
                        placeholder="vd: 0912345678 anh A"
                        className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        onChange={(e) => changePhone(e, i)}
                      />
                      {i !== 0 && (
                        <IconButton variant="text">
                          <TrashIcon className="h-5 w-5 text-red-500" onClick={() => removePhone(i)} />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-2">
                <Button
                  size="sm"
                  color="blue"
                  className="normal-case"
                  onClick={() => {
                    setArrPhone([...arrPhone, '']);
                  }}
                >
                  Thêm
                </Button>
              </div>
            </div>

            {/* CHI TIẾT BẤT ĐỘNG SẢN */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">Chi tiết BĐS</div>
              <div className="grid grid-cols-1 p-3">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>
                      Bán - Cho Thuê? <b className="text-red-500">*</b>
                    </b>
                  </label>
                </div>
                <div className="grid grid-rows-2">
                  <Checkbox
                    label="Bán"
                    onChange={(e) => {
                      setArrBDS({ ...arrBDS, isbandat: e.target.checked ? 1 : 0 });
                    }}
                    checked={arrBDS.isbandat > 0}
                  />
                  <Checkbox
                    label="Cho thuê"
                    onChange={(e) => {
                      setArrBDS({ ...arrBDS, ischothue: e.target.checked ? 1 : 0 });
                    }}
                    checked={arrBDS.ischothue > 0}
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Rộng đường trước nhà <b className="text-red-500">*</b>
                      </b>
                    </label>

                    <Input
                      value={arrBDS?.rongduong}
                      type="number"
                      placeholder="vd: 6,8m nhập 6.8"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">m</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, rongduong: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Quy Hoạch <b className="text-red-500">*</b>
                      </b>
                    </label>
                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(5);
                          getQH();
                        }}
                      >
                        <span className="mr-2"> {arrBDS && arrBDS.quyhoach ? arrBDS.quyhoach : 'Chọn Quy Hoạch'}</span>
                        {open === 5 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                      </button>
                      {open === 5 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('qhs', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.qhs &&
                            arrOptionSelectSearch?.qhs.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  setArrBDS({ ...arrBDS, quyhoach: row?.name });
                                  handleOpen(0);
                                }}
                              >
                                {row?.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Pháp lý</b>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(6);
                          getPhaply();
                        }}
                      >
                        <span className="mr-2"> {arrBDS && arrBDS.phaply ? arrBDS.phaply : 'Chọn Pháp Lý'}</span>
                        {open === 6 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                      </button>
                      {open === 6 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('phaply', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.phaply &&
                            arrOptionSelectSearch?.phaply.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  setArrBDS({ ...arrBDS, phaply: row?.name });
                                  handleOpen(0);
                                }}
                              >
                                {row?.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="lg:col-span-2">
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Hướng BĐS <b className="text-red-500">*</b>
                      </b>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(7);
                          getHuongBds();
                        }}
                      >
                        {' '}
                        {arrBDS && arrBDS.huongbds ? (
                          <span className="mr-2 grid grid-cols-3 gap-2">
                            {arrBDS.huongbds?.split(',')?.map((row, i) => (
                              <Chip
                                value={row}
                                variant="ghost"
                                icon={<XCircleIcon />}
                                key={i}
                                onClick={() => removeDataBds(arrBDS.huongbds?.split(','), 'huongbds', i)}
                              />
                            ))}
                          </span>
                        ) : (
                          <span className="mr-2">Chọn Hướng, Có thể chọn nhiều hướng...</span>
                        )}
                        {open === 7 ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
                      </button>
                      {open === 7 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('huongbds', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.huongbds &&
                            arrOptionSelectSearch?.huongbds.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  if (!checkText(arrBDS.huongbds, row?.name)) {
                                    setArrBDS({
                                      ...arrBDS,
                                      huongbds: `${arrBDS['huongbds'] ? arrBDS['huongbds'] + ',' : ''}${row?.name}`,
                                    });
                                    handleOpen(0);
                                  }
                                }}
                              >
                                <b className={`${checkText(arrBDS.huongbds, row?.name) ? 'text-gray-500' : ''}`}>
                                  {row?.name}
                                </b>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="my-6 border-blue-gray-100" />

                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Loại Vị trí (MT, Hẻm...)<b className="text-red-500">*</b>
                      </b>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(8);
                          getLoaiVitri();
                        }}
                      >
                        {' '}
                        {arrBDS && arrBDS.loaivitri ? (
                          <span className="mr-2 grid grid-cols-2 gap-2">
                            {arrBDS.loaivitri?.split(',')?.map((row, i) => (
                              <Chip
                                value={row}
                                variant="ghost"
                                icon={<XCircleIcon />}
                                key={i}
                                onClick={() => removeDataBds(arrBDS.loaivitri?.split(','), 'loaivitri', i)}
                              />
                            ))}
                          </span>
                        ) : (
                          <span className="mr-2">Nhấn, Có thể chọn nhiều...</span>
                        )}
                      </button>
                      {open === 8 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('loaivitri', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.loaivitri &&
                            arrOptionSelectSearch?.loaivitri.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  if (!checkText(arrBDS.loaivitri, row?.name)) {
                                    setArrBDS({
                                      ...arrBDS,
                                      loaivitri: `${arrBDS['loaivitri'] ? arrBDS['loaivitri'] + ',' : ''}${row?.name}`,
                                    });
                                    handleOpen(0);
                                  }
                                }}
                              >
                                <b className={`${checkText(arrBDS.loaivitri, row?.name) ? 'text-gray-500' : ''}`}>
                                  {row?.name}
                                </b>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Loại BĐS<b className="text-red-500">*</b>
                      </b>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(9);
                          getLoaiBDS();
                        }}
                      >
                        {' '}
                        {arrBDS && arrBDS.loaibds ? (
                          <span className="mr-2 grid grid-cols-2 gap-2">
                            {arrBDS.loaibds?.split(',')?.map((row, i) => (
                              <Chip
                                value={row}
                                variant="ghost"
                                icon={<XCircleIcon />}
                                key={i}
                                onClick={() => removeDataBds(arrBDS.loaibds?.split(','), 'loaibds', i)}
                              />
                            ))}
                          </span>
                        ) : (
                          <span className="mr-2">Nhấn, Có thể chọn nhiều...</span>
                        )}
                      </button>
                      {open === 9 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('loaibds', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.loaibds &&
                            arrOptionSelectSearch?.loaibds.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  if (!checkText(arrBDS.loaibds, row?.name)) {
                                    setArrBDS({
                                      ...arrBDS,
                                      loaibds: `${arrBDS['loaibds'] ? arrBDS['loaibds'] + ',' : ''}${row?.name}`,
                                    });
                                    handleOpen(0);
                                  }
                                }}
                              >
                                <b className={`${checkText(arrBDS.loaibds, row?.name) ? 'text-gray-500' : ''}`}>
                                  {row?.name}
                                </b>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Tiện Nghi & Kết Cấu </b>
                      <Tooltip content="..." placement="right">
                        <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(10);
                          getTiennghi();
                        }}
                      >
                        {' '}
                        {arrBDS && arrBDS.tiennghi ? (
                          <span className="mr-2 grid grid-cols-2 gap-2">
                            {arrBDS.tiennghi?.split(',')?.map((row, i) => (
                              <Chip
                                value={row}
                                variant="ghost"
                                icon={<XCircleIcon />}
                                key={i}
                                onClick={() => removeDataBds(arrBDS.tiennghi?.split(','), 'tiennghi', i)}
                              />
                            ))}
                          </span>
                        ) : (
                          <span className="mr-2">SThượng,hầm,hồ bơi....</span>
                        )}
                      </button>
                      {open === 10 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('tiennghi', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.tiennghi &&
                            arrOptionSelectSearch?.tiennghi.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  if (!checkText(arrBDS.tiennghi, row?.name)) {
                                    setArrBDS({
                                      ...arrBDS,
                                      tiennghi: `${arrBDS['tiennghi'] ? arrBDS['tiennghi'] + ',' : ''}${row?.name}`,
                                    });
                                    handleOpen(0);
                                  }
                                }}
                              >
                                <b className={`${checkText(arrBDS.tiennghi, row?.name) ? 'text-gray-500' : ''}`}>
                                  {row?.name}
                                </b>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Đặc trưng </b>
                      <Tooltip content="..." placement="right">
                        <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <div className="relative">
                      <button
                        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-400 rounded-md shadow-sm"
                        onClick={() => {
                          handleOpen(11);
                          getDactrung();
                        }}
                      >
                        {' '}
                        {arrBDS && arrBDS.dactrung ? (
                          <span className="mr-2 grid grid-cols-2 gap-2">
                            {arrBDS.dactrung?.split(',')?.map((row, i) => (
                              <Chip
                                value={row}
                                variant="ghost"
                                icon={<XCircleIcon />}
                                key={i}
                                onClick={() => removeDataBds(arrBDS.dactrung?.split(','), 'dactrung', i)}
                              />
                            ))}
                          </span>
                        ) : (
                          <span className="mr-2">Nhấn, Có thể chọn nhiều...</span>
                        )}
                      </button>
                      {open === 11 && (
                        <div
                          className={`absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 w-full z-10`}
                        >
                          <Input
                            // value={''}

                            className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                            labelProps={{
                              className: 'hidden',
                            }}
                            onChange={(e) => handleSearchOptionSelect('dactrung', e.target.value, 'name')}
                          />

                          <div className="pl-1">
                            <b className="text-sm">vui lòng nhập thêm từ 1 ký tự trở lên</b>
                          </div>
                          {arrOptionSelectSearch?.dactrung &&
                            arrOptionSelectSearch?.dactrung.map((row, index) => (
                              <div
                                className="block px-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                                key={index}
                                onClick={() => {
                                  if (!checkText(arrBDS.dactrung, row?.name)) {
                                    setArrBDS({
                                      ...arrBDS,
                                      dactrung: `${arrBDS['dactrung'] ? arrBDS['dactrung'] + ',' : ''}${row?.name}`,
                                    });
                                    handleOpen(0);
                                  }
                                }}
                              >
                                <b className={`${checkText(arrBDS.dactrung, row?.name) ? 'text-gray-500' : ''}`}>
                                  {row?.name}
                                </b>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <hr className="my-6 border-blue-gray-100" />

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Thông tin thêm (khách có thể đọc được)</b>
                    </label>

                    <Textarea
                      value={arrBDS?.notekhach}
                      type="text"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, notekhach: e.target.value });
                      }}
                    />
                  </div>
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Thông tin nội bộ (chỉ Kland đọc được) - Không Nhập SĐT bên chủ nhà ở đây</b>
                    </label>

                    <Textarea
                      value={arrBDS?.notenoibo}
                      type="text"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, notenoibo: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-6 gap-4  mt-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        Diện tích đất ở <b className="text-red-500">*</b>
                      </b>
                    </label>

                    <Input
                      value={arrBDS?.dientich}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">㎡</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, dientich: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        DT Đất nông nghiệp <b className="text-red-500">*</b>
                      </b>
                    </label>

                    <Input
                      value={arrBDS?.dientichdatnn}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">㎡</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, dientichdatnn: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        {' '}
                        Ngang <b className="text-red-500">*</b>{' '}
                      </b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.ngang}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">m</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, ngang: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Dài</b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.dai}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">m</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, dai: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        {' '}
                        Số tầng <b className="text-red-500">*</b>{' '}
                      </b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.sotang}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ml-[-17px]">tầng</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, sotang: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>
                        {' '}
                        Số phòng <b className="text-red-500">*</b>{' '}
                      </b>
                    </label>

                    <Input
                      value={arrBDS?.sophong}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ml-[-33px] ">phòng</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, sophong: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-5 gap-4  mt-4">
                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Số WC</b>
                    </label>

                    <Input
                      value={arrBDS?.sowc}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ml-[-10px]">WC</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, sowc: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Tổng DT sàn (các tầng)</b>
                    </label>

                    <Input
                      value={arrBDS?.dientichsan}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">㎡</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, dientichsan: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Ngang hậu</b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.nganghau}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ">m</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, nganghau: e.target.value });
                      }}
                    />
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Thu nhập từ cho thuê (số tiền)</b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.giathue}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ml-[-15px]">vnđ</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, giathue: e.target.value });
                      }}
                    />
                    <b className="text-red-600 text-sm">{convertNumberToWords(arrBDS?.giathue)}</b>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Giá bán (số tiền)</b>
                      <Tooltip content="..." placement="right">
                        <InformationCircleIcon className="h-4 w-4 text-gray-500 cursor-pointer" />
                      </Tooltip>
                    </label>

                    <Input
                      value={arrBDS?.gia}
                      type="number"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      icon={<b className="border-l-2 p-[5px] mt-[-7px] ml-[-15px]">vnđ</b>} //㎡
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setArrBDS({ ...arrBDS, gia: e.target.value });
                      }}
                    />
                    <b className="text-red-600 text-sm">{convertNumberToWords(arrBDS?.gia)}</b>
                  </div>
                </div>
              </div>
            </div>

            {/* HÌNH ẢNH */}
            <div className="grid border border-gray-400 rounded-md">
              <div className="font-bold border-b-2 border-gray-400 p-2">
                Hình ảnh về BĐS (Tổng tối đa 15 tập tin hình + sổ)
              </div>
              <div className="grid lg:grid-cols-3 grid-cols-1 gap-2 p-3">
                <div>
                  <h3>
                    <b>Hinh ảnh BĐS</b>
                  </h3>
                  <h3 className="mt-4">
                    <b>* tối đa 10 hình</b>
                  </h3>
                  <div className="text-gray-500 text-sm">
                    <p>CHỈ HÌNH NHÀ, KHÔNG SỔ.</p>
                    <p>Hình đầu tiên (rìa trái) sẽ là hình đại diện BĐS</p>
                  </div>
                </div>

                <div className="col-span-2">
                  {imageURLS.length <= 10 && (
                    <Button onClick={onButtonClickFileImage}>CHỌN HÌNH ẢNH (KHÔNG VIDEO)</Button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    name="image"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onFilechange}
                  />{' '}
                  <div className="mt-1">Hiển thị ảnh ở đây</div>
                  <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
                    {checkInfo &&
                      imageURLsUpdate?.map((imageSrc, i) => (
                        <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                          <img src={`${API_URL}/imgshop?name=${imageSrc}`} alt="not fount" width={'100%'} />
                          <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                              <span onClick={() => removeImageUpdate(i)} className="cursor-pointer">
                                xóa
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                    {imageURLS?.map((imageSrc, i) => (
                      <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                        <img src={imageSrc} alt="not fount" width={'100%'} />
                        <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                          <div>
                            <span onClick={() => removeImage(i)} className="cursor-pointer">
                              xóa
                            </span>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                </div>
              </div>

              <hr className="my-6 border-blue-gray-100" />

              <div className="grid lg:grid-cols-3 grid-cols-1 gap-2 p-3">
                <div>
                  <h3>
                    <b>Sổ, giấy tờ</b>
                  </h3>
                  <h3 className="mt-4">
                    <b>* tối đa 5 (hình hoặc tập tin)</b>
                  </h3>
                  <div className="text-gray-500 text-sm">
                    <p>Chỉ liên quan đến Sổ, giấy tờ BĐS</p>
                    <p>Tập tin: pdf, word, excel, zip, jpg, png </p>
                    <p>Hình đầu tiên (rìa trái) sẽ là hình đại diện BĐS</p>
                  </div>
                </div>

                <div className="col-span-2">
                  {imageURLSSo.length <= 5 && (
                    <Button onClick={onButtonClickFileImageSo}>CHỌN SỔ: HÌNH ẢNH, TẬP TIN (KHÔNG VIDEO)</Button>
                  )}
                  <input
                    type="file"
                    // accept="image/*"
                    multiple
                    name="image"
                    id="file"
                    ref={inputFileSo}
                    style={{ display: 'none' }}
                    onChange={onFilechangeSo}
                  />{' '}
                  <div className="mt-1">Hiển thị ảnh ở đây</div>
                  <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
                    {checkInfo &&
                      imageURLsSoUpdate?.map((imageSrc, i) => (
                        <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                          {typeImg(imageSrc) ? (
                            <img src={`${API_URL}/imgshop?name=${imageSrc}`} alt="not fount" width={'100%'} />
                          ) : (
                            <a href={`${API_URL}/imgshop?name=${imageSrc}`} download={imageSrc} width={'100%'}>
                              {'file_' + i + 1}
                            </a>
                          )}
                          <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                              <span onClick={() => removeImageSoUpdate(i)} className="cursor-pointer">
                                xóa
                              </span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                    {imageURLSSo?.map((imageSrc, i) => (
                      <figure className="relative w-full border border-amber-500 border-dashed p-1" key={i}>
                        {imageSrc ? (
                          <img src={imageSrc} alt="not fount" width={'100%'} />
                        ) : (
                          <a href={URL.createObjectURL(imagesSo[i])} download={imagesSo[i].name}>
                            {imagesSo[i].name}
                          </a>
                        )}
                        <figcaption className="absolute bottom-4 left-2/4 flex px-1 -translate-x-2/4 justify-center rounded-xl border border-white bg-red-900 text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                          <div>
                            <span onClick={() => removeImageSo(i)} className="cursor-pointer">
                              xóa
                            </span>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2">
            {warningMessage && <p className="text-red-500 font-bold">{warningMessage}</p>}
            <Button
              color="orange"
              onClick={checkInfo ? subUpdateDefault : subAddDefault}
              disabled={isDisabled}
              loading={loading}
            >
              {checkInfo ? 'CẬP NHẬT' : 'LƯU THÊM MỚI'}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
