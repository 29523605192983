import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Button, IconButton, Input, Option, Select } from '@material-tailwind/react';
import FrameShowBds from '../../../components/FrameShowBds';
import {
  countThongKeViewSo,
  listProductIDAllCount,
  listProductIDAllDaxemCount,
  listProductIDAllShowCount,
  listProductIDShow,
  listProductIDShowDaxem,
  listProductTodayCount,
} from '../../../services/apiBds';
import { useEffect, useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { getCookie, laySoTuDoanText } from '../../../utils/helper';
import LoadingFrame from '../../../components/loadingFrame';
import { listAllLoaibds, listAllStreets } from '../../../services/apiCategory';

export default function Home() {
  const arrSapxep = [
    { title: 'Sắp xếp - Mới cập nhật', value: '1' },
    { title: 'Cập nhật: Mới đây', value: '2' },
    { title: 'Cập nhật: Cũ trước', value: '3' },
    { title: 'Tổng giảm giá', value: '4' },
    { title: 'Giá m2 thấp', value: '5' },
    // { title: 'Giá m2 thấp hơn TT-KV-PK', value: '6' },
    { title: 'Thu nhập cao', value: '7' },
    { title: 'Đường trước nhà', value: '8' },
    { title: 'Số Tầng', value: '9' },
    { title: 'Giá: Giảm dần', value: '10' },
    { title: 'Giá: Tăng dần', value: '11' },
    { title: 'Ngày: cũ trước', value: '12' },
    { title: 'Ngày: mới nhất', value: '13' },
    { title: 'Ngang lớn trước', value: '14' },
    { title: 'Ngang nhỏ trước', value: '15' },
    { title: 'Dài - Giảm dần', value: '16' },
    { title: 'Dài - Tăng dần', value: '17' },
    { title: 'Tổng DT Đất', value: '18' },
    // {title: 'DTXD (thổ cư)', value: '19'},
  ];
  const auth = getCookie('token');
  const [resultListBds, setResultListBds] = useState([]);
  const [resultListBdsDaXem, setResultListBdsDaXem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countBds, setCountBds] = useState(0);
  const [countBdsDaXem, setCountBdsDaXem] = useState(0);
  const [page, setPage] = useState(1);
  const [objBoLoc, setObjBoLoc] = useState({ code: '', status: '1', text: '', loaibds: '', xa: '' });
  const [countThongKe, setCountThongKe] = useState({ xemso: '', baiviet: '', chuaduyet: '', sdt: '' });
  const [arrOptionSelect, setArrOptionSelect] = useState({ streets: [], loaibds: [] });

  const listBdsAll = async (pages = 1, status = '1', code = '', text = '') => {
    setLoading(true);
    const resultBDSAll = await listProductIDShow(pages, status, code, text);
    // console.log(resultBDSAll);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      const countBds = await listProductIDAllShowCount(status, code, text);
      setCountBds(countBds.totalRows);
      setLoading(false);
    } else {
      setLoading(false);
      setResultListBds([]);
      setCountBds(0);
    }
  };

  const listBdsAllDaXem = async (pages = 1, username, code = '', text = '', loaibds = '', xa = '') => {
    const resultBDSAll = checkStartingText(objBoLoc.text)
      ? await listProductIDShowDaxem(pages, '', username, laySoTuDoanText(objBoLoc.text), '', loaibds, xa)
      : await listProductIDShowDaxem(pages, '', username, laySoTuDoanText(code), text, loaibds, xa);
    // console.log(resultBDSAll);
    if (resultBDSAll.length > 0) {
      setResultListBdsDaXem(resultBDSAll);

      const countBds = checkStartingText(objBoLoc.text)
        ? await listProductIDAllDaxemCount('', username, laySoTuDoanText(objBoLoc.text), '', loaibds, xa)
        : await listProductIDAllDaxemCount('', username, laySoTuDoanText(code), text, loaibds, xa);
      setCountBdsDaXem(countBds.totalRows);
    } else {
      setResultListBdsDaXem([]);
    }
  };

  const listBdsAllSearch = async (pages = 1, status = '1', code = '', text = '', loaibds = '', xa = '') => {
    const resultBDSAll = checkStartingText(objBoLoc.text)
      ? await listProductIDShow(pages, status, laySoTuDoanText(objBoLoc.text), '', loaibds, xa)
      : await listProductIDShow(pages, status, laySoTuDoanText(code), text, loaibds, xa);
    setLoading(true);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll);
      setLoading(false);
      const countBds = checkStartingText(objBoLoc.text)
        ? await listProductIDAllShowCount(status, laySoTuDoanText(objBoLoc.text), '', loaibds, xa)
        : await listProductIDAllShowCount(status, laySoTuDoanText(code), text, loaibds, xa);

      setCountBds(countBds.totalRows);
    } else {
      setResultListBds([]);
      setLoading(false);
      setCountBds(0);
    }
  };

  const getItemProps = (index) => ({
    variant: page === index ? 'filled' : 'text',
    color: 'gray',
    onClick: () => {
      setPage(index);
      listBdsAllSearch(index, objBoLoc.status, objBoLoc.code, objBoLoc.text, objBoLoc.loaibds, objBoLoc.xa);
      scrollToTop();
    },
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const next = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total > page) {
      setPage(page + 1);
      listBdsAllSearch(page + 1, objBoLoc.status, objBoLoc.code, objBoLoc.text, objBoLoc.loaibds, objBoLoc.xa);
      scrollToTop();
    }
  };

  const prev = () => {
    if (page === countBds) return;

    const total = Math.ceil(countBds / 50);
    if (total <= page && page > 1) {
      setPage(page - 1);
      listBdsAllSearch(page - 1, objBoLoc.status, objBoLoc.code, objBoLoc.text, objBoLoc.loaibds, objBoLoc.xa);
      scrollToTop();
    }
  };

  // const getStreets = async () => {
  //   const resultAPIXA = await listAllStreets(1, 1000);
  //   if (resultAPIXA.length > 0) {
  //     setArrOptionSelect({ ...arrOptionSelect, streets: resultAPIXA });
  //   }
  // };

  const getLoaiBDS = async () => {
    const resultAPI = await listAllLoaibds(1, 1000);
    if (resultAPI.length > 0) {
      setArrOptionSelect({ ...arrOptionSelect, loaibds: resultAPI });
      const resultAPIXA = await listAllStreets(1, 1000);
      if (resultAPIXA.length > 0) {
        setArrOptionSelect({ loaibds: resultAPI, streets: resultAPIXA });
      }
    }
  };
  const thongkeNgayMoi = async () => {
    const checkTotalSo = await countThongKeViewSo({ data: { truong: 'xemso' } });
    const checkTotalSDT = await countThongKeViewSo({ data: { truong: 'xemsdt' } });
    const checkTotalBaiViet = await listProductTodayCount();
    const countBds = await listProductIDAllCount(3);
    setCountThongKe({
      xemso: checkTotalSo.view,
      sdt: checkTotalSDT.view,
      baiviet: checkTotalBaiViet.totalRows,
      chuaduyet: countBds.totalRows,
    });
  };

  useEffect(() => {
    listBdsAll(1, '1');
    listBdsAllDaXem(1, auth.username);
    // document.title = '';
    thongkeNgayMoi();
    getLoaiBDS();
  }, []);

  function checkStartingText(text) {
    const regex = /^#?KL/;
    return regex.test(text);
  }

  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      listBdsAllSearch(1, objBoLoc.status, objBoLoc.code, objBoLoc.text, objBoLoc.loaibds, objBoLoc.xa);
      listBdsAllDaXem(1, auth.username, objBoLoc.code, objBoLoc.text, objBoLoc.loaibds, objBoLoc.xa);
    }
  };

  const pagesToShow = 5;
  const totalNumbers = Array.from(new Array(Math.ceil(countBds / 50)), (val, index) => index + 1);

  const firstPage = Math.max(1, page - Math.floor(pagesToShow / 2));
  const lastPage = Math.min(Math.ceil(countBds / 50), firstPage + pagesToShow - 1);

  const prevPages = firstPage > 1 ? [1, '...'] : [];
  const nextPages = lastPage < Math.ceil(countBds / 50) ? ['...', Math.ceil(countBds / 50)] : [];

  const pageNumbers = [...prevPages, ...totalNumbers.slice(firstPage - 1, lastPage), ...nextPages];

  const uniqueData = (data, key) => {
    return data.filter((item, index, self) => index === self.findIndex((t) => t[key] === item[key]));
  };

  return (
    <div className="m-auto">
      {loading && (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="lg:col-span-3">
            <LoadingFrame />
          </div>
          <div className="hidden lg:block lg:col-span-2">
            <LoadingFrame />
          </div>
        </div>
      )}
      {!loading && (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="sm:hidden">
            <div className="mt-1 mb-2">
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm nhanh</b>
              </label>
              <Input
                //   value={arrBDS?.sonha}
                placeholder="Tìm Tên Đường, mã số hoặc từ khóa... "
                className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, text: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </div>
            <div>
              <div className="grid grid-cols-2 gap-2 ">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Loại BĐS</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.loaibds}
                      onChange={(e) => {
                        listBdsAllSearch(1, objBoLoc.status, objBoLoc.code, objBoLoc.text, e.target.value, objBoLoc.xa);
                        setObjBoLoc({ ...objBoLoc, loaibds: e.target.value });
                        listBdsAllDaXem(1, auth.username, objBoLoc.code, objBoLoc.text, e.target.value, objBoLoc.xa);
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">
                        <b>Chọn Loại BĐS</b>
                      </option>
                      {arrOptionSelect?.loaibds.length > 0 &&
                        arrOptionSelect?.loaibds.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>

                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Phường, xã</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.xa}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                        );
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                        );
                        setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">
                        <b>Chọn Phường, xã</b>
                      </option>
                      {/* {arrOptionSelect?.streets.length === 0 && (
                        <option>
                          <b>Đang tải ...</b>
                        </option>
                      )} */}
                      {arrOptionSelect?.streets.length > 0 &&
                        uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                          <option key={index} value={row?.xa}>
                            {row?.xa},{row?.huyen}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </div>

                <div className="grid justify-center">
                  {(countThongKe?.baiviet || countThongKe?.chuaduyet || countThongKe?.xemso || countThongKe?.sdt) && (
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Hôm nay có gì mới</b>
                    </label>
                  )}
                  {countThongKe?.baiviet ? <p>+{countThongKe?.baiviet} bđs mới</p> : ''}
                  {countThongKe?.chuaduyet ? <p>+{countThongKe?.chuaduyet} bđs chưa duyệt</p> : ''}
                  {/* <p>+5 lượt bình luận mới</p> */}
                  {countThongKe?.xemso ? <p>+{countThongKe?.xemso} lượt xem sổ</p> : ''}
                  {countThongKe?.sdt ? <p>+{countThongKe?.sdt} lượt xem SĐT</p> : ''}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:col-span-3">
            <div className="flex items-center justify-center mb-2 gap-4">
              <label className="font-bold text-lg">{countBds} bđs đang bán</label>
              <div className="lg:max-w-xs lg:w-full">
                <div className="relative">
                  <select
                    onChange={(e) => listBdsAllSearch(1, e.target.value, laySoTuDoanText(objBoLoc.code), objBoLoc.text)}
                    className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                  >
                    {arrSapxep.map((row, i) => (
                      <option key={i} value={row.value}>
                        {row.title}
                      </option>
                    ))}
                  </select>
                  <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                </div>
              </div>
            </div>
            {resultListBds.length > 0 &&
              resultListBds?.map((row, i) => (
                <div key={i}>
                  {' '}
                  <FrameShowBds data={row} />
                </div>
              ))}
            <hr className="my-4 border-blue-gray-50" />
            <div className="flex items-center justify-center">
              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={prev}
                  disabled={page === 1}
                >
                  <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Trước
                </Button>
              </div>
              <div className="flex">
                {pageNumbers.map((number, index) =>
                  number !== '...' ? (
                    <IconButton key={index} {...getItemProps(number)} size="sm">
                      {number}
                    </IconButton>
                  ) : (
                    <IconButton key={index} variant="text" size="sm">
                      {number}
                    </IconButton>
                  ),
                )}
              </div>

              <div>
                <Button
                  variant="text"
                  size="sm"
                  className="flex items-center gap-1"
                  onClick={next}
                  disabled={page === Math.ceil(countBds / 50)}
                >
                  Sau
                  <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>

          <div className="lg:col-span-2 gap-4">
            <div className="hidden lg:block mt-1 mb-2">
              <label className="flex items-end gap-1 text-sm mb-1">
                <b>Tìm nhanh</b>
              </label>
              <Input
                //   value={arrBDS?.sonha}
                placeholder="Tìm Tên Đường, mã số hoặc từ khóa... "
                className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                onChange={(e) => {
                  setObjBoLoc({ ...objBoLoc, text: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </div>
            <div className="hidden lg:block">
              <div className="grid grid-cols-2 gap-2 ">
                <div>
                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Loại BĐS</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.loaibds}
                      onChange={(e) => {
                        listBdsAllSearch(1, objBoLoc.status, objBoLoc.code, objBoLoc.text, e.target.value, objBoLoc.xa);
                        setObjBoLoc({ ...objBoLoc, loaibds: e.target.value });
                        listBdsAllDaXem(1, auth.username, objBoLoc.code, objBoLoc.text, e.target.value, objBoLoc.xa);
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">
                        <b>Chọn Loại BĐS</b>
                      </option>
                      {arrOptionSelect?.loaibds.length > 0 &&
                        arrOptionSelect?.loaibds.map((row, i) => (
                          <option key={i} value={row.name}>
                            {row.name}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>

                  <label className="flex items-end gap-1 text-sm mb-1">
                    <b>Phường, xã</b>
                  </label>
                  <div className="relative mb-2">
                    <select
                      value={objBoLoc.xa}
                      onChange={(e) => {
                        listBdsAllSearch(
                          1,
                          objBoLoc.status,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                        );
                        listBdsAllDaXem(
                          1,
                          auth.username,
                          objBoLoc.code,
                          objBoLoc.text,
                          objBoLoc.loaibds,
                          e.target.value,
                        );
                        setObjBoLoc({ ...objBoLoc, xa: e.target.value });
                      }}
                      className="w-full border-gray-400 rounded-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 pl-3 pr-8 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md appearance-none cursor-pointer"
                    >
                      <option value="">
                        <b>Chọn Phường, xã</b>
                      </option>
                      {/* {arrOptionSelect?.streets.length === 0 && (
                        <option>
                          <b>Đang tải ...</b>
                        </option>
                      )} */}
                      {arrOptionSelect?.streets.length > 0 &&
                        uniqueData(arrOptionSelect?.streets, 'xa')?.map((row, index) => (
                          <option key={index} value={row?.xa}>
                            {row?.xa},{row?.huyen}
                          </option>
                        ))}
                    </select>
                    <ChevronUpDownIcon className="h-5 w-5 ml-1 absolute top-2.5 right-2.5 text-slate-700" />
                  </div>
                </div>

                <div className="grid justify-center">
                  {(countThongKe?.baiviet || countThongKe?.chuaduyet || countThongKe?.xemso || countThongKe?.sdt) && (
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b>Hôm nay có gì mới</b>
                    </label>
                  )}
                  {countThongKe?.baiviet ? <p>+{countThongKe?.baiviet} bđs mới</p> : ''}
                  {countThongKe?.chuaduyet ? <p>+{countThongKe?.chuaduyet} bđs chưa duyệt</p> : ''}
                  {/* <p>+5 lượt bình luận mới</p> */}
                  {countThongKe?.xemso ? <p>+{countThongKe?.xemso} lượt xem sổ</p> : ''}
                  {countThongKe?.sdt ? <p>+{countThongKe?.sdt} lượt xem SĐT</p> : ''}
                </div>
              </div>
            </div>

            {resultListBdsDaXem.length > 0 && (
              <div>
                <hr className="lg:hidden my-2 border-blue-gray-50" />
                <div className="text-center text-2xl my-5">
                  <b>Có thể bạn đã quên</b>
                </div>
                {resultListBdsDaXem?.map((row, i) => (
                  <div key={i}>
                    {' '}
                    <FrameShowBds data={row} />
                  </div>
                ))}
                {countBdsDaXem > 20 && (
                  <div>
                    <hr className="my-4 border-blue-gray-50" />
                    <div className="flex justify-center">
                      <Button variant="outlined" color="orange">
                        Xem thêm
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
