import { Button, Card, Drawer, IconButton } from '@material-tailwind/react';
import { refreshTokenNew } from '../../utils/helper';
import { Link } from 'react-router-dom';
import images from '../../assets/images';
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

function MainLayout({ children }) {
  refreshTokenNew();
  const [openDrawers, setOpenDrawers] = useState(false);
  const openDrawer = () => setOpenDrawers(true);
  const closeDrawer = () => setOpenDrawers(false);
  return (
    <div className="m-auto p-2 lg:p-6 bg-white overflow-x-hidden">
      <div className="container mx-auto hidden lg:block mb-8">
        <div className="flex items-center">
          <Link to={`/`}>
            <div className="text-3xl font-bold cursor-pointer text-amber-800 mr-10">
              <img src={images.logokland} alt="1 " width={'80px'} />
            </div>
          </Link>
          <span className="text-base font-bold col-span-2 cursor-pointer text-amber-600 mr-10">DANH MỤC SẢN PHẨM</span>
          <span className="text-base font-bold cursor-pointer mr-10">CÓ GÌ MỚI?</span>
          <span className="text-base font-bold cursor-pointer mr-10">BẢNG GIÁ M2</span>
          <Link to="/user">
            <span className="text-base font-bold col-span-2 cursor-pointer mr-10">TÀI KHOẢN CỦA TÔI</span>
          </Link>
          <span className="mr-10">
            <Link to="/addbds">
              <Button color="orange">THÊM BĐS</Button>
            </Link>
          </span>
          <span className="mr-10">
            <Link to="/dashboard">
              <Button color="orange">Trang quản lý</Button>
            </Link>
          </span>
        </div>
      </div>

      <div className=" w-full">
        <Card className="lg:hidden grid w-full p-1 mb-2  rounded-xl bg-orange-400">
          <div className="grid grid-cols-3 items-center">
            <div>
              <IconButton variant="text" className="text-lg text-white" onClick={openDrawer}>
                <Bars3Icon className="h-10 w-10" />
              </IconButton>
            </div>
            <Link to={`/`}>
              <div className="flex justify-center items-center">
                <img src={images.logo} width={'60px'} className="sepia-[1]" />{' '}
                <span className="font-bold text-orange-50 text-2xl tracking-[3px]">KEYLAND</span>
              </div>
            </Link>
            <div className="flex justify-end">
              {' '}
              <MagnifyingGlassIcon className="h-10 w-10 text-white text-right" />
            </div>
          </div>
        </Card>
      </div>

      <Drawer open={openDrawers} onClose={closeDrawer} className="p-4 bg-orange-50">
        <div className="mb-6 flex items-center justify-between py-3">
          <p className="text-black text-xl">DANH MỤC SẢN PHẨM</p>
        </div>
        <hr className="my-3 border-blue-gray-500" />
        <div className="mb-6 flex items-center justify-between py-3">
          <p className="black-black text-xl">CÓ GÌ MỚI?</p>
        </div>
        <hr className="my-3 border-blue-gray-500" />
        <div className="mb-6 flex items-center justify-between py-3">
          <p className="black-black text-xl">BẢNG GIÁ M2</p>
        </div>
        <hr className="my-3 border-blue-gray-500" />
        <div className="mb-6 flex items-center justify-between py-3">
          <p className="black-black text-xl">TÀI KHOẢN CỦA TÔI</p>
        </div>
        <hr className="my-3 border-blue-gray-500" />
        <Link to="/addbds">
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">THÊM TIN BĐS</p>
          </div>
        </Link>
        <hr className="my-3 border-blue-gray-500" />
        <Link to="/dashboard">
          <div className="mb-6 flex items-center justify-between py-3">
            <p className="black-black text-xl">TRANG QUẢN LÝ</p>
          </div>
        </Link>
      </Drawer>
      <div>{children}</div>
    </div>
  );
}

export default MainLayout;
