import { ClockIcon, HeartIcon, UserIcon } from '@heroicons/react/24/outline';
import images from '../assets/images';
import { API_URL } from '../utils/urls';
import { convertNumberToWords, getCookie } from '../utils/helper';
import { Button, Dialog, DialogHeader, DialogBody, Input } from '@material-tailwind/react';
import { useState } from 'react';
import { ArrowDownTrayIcon, BellAlertIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { checkPhoneChuDat, countThongKeViewSo } from '../services/apiBds';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import DialogBds from './DialogBds';
import { historysUser } from '../utils/history';

const FrameShowBds = ({ data = {} }) => {
  const imageAvt =
    data?.anhto?.split(',').length > 0 ? `${API_URL}/imgshop?name=${data?.anhto?.split(',')[0]}` : images.banner_login;
  const auth = getCookie('token');
  const [open, setOpen] = useState(false);
  const [isSubModal, setIsSubModal] = useState(0);
  const [pass, setPass] = useState('');
  const [phoneChudat, setPhoneChudat] = useState([]);
  const [isPhoneChudat, setIsPhoneChudat] = useState('');

  const handleOpen = () => {
    setOpen(!open);
    setPhoneChudat([]);
  };

  const typeImg = (str) => {
    const lastIndex = str.lastIndexOf('.');
    const result = str.substring(lastIndex + 1);
    if (result === 'png' || result === 'jpg' || result === 'jpeg') {
      return true;
    } else {
      return false;
    }
  };

  const downloadImage = (imageURL, index, key) => {
    saveAs(imageURL, `${index + 1}_${Date.now()}`);
    historysUser({
      id_bds: data?.id,
      truong: key,
      noidung: '1',
    });
  };

  const downloadImagesAsZip = (arrImage, namezip, key) => {
    const imageUrls = arrImage?.split(',').length > 0 ? arrImage?.split(',') : [];
    historysUser({
      id_bds: data?.id,
      truong: key,
      noidung: imageUrls.length,
    });

    const zip = new JSZip();

    const promises = imageUrls.map((imageUrl, index) => {
      return fetch(`${API_URL}/imgshop?name=${imageUrl}`)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(imageUrl, blob);
        });
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, namezip + '.zip');
      });
    });
  };

  const checkInfoChuDat = async () => {
    const body = {
      user: auth.username,
      pass: pass,
      code: data.id,
    };
    const result = await checkPhoneChuDat(body);
    if (result.length > 0) {
      setPass('');
      setPhoneChudat(result[0]['sdtchudat']?.split(','));
      setIsPhoneChudat('');
      historysUser({
        id_bds: data?.id,
        truong: 'xemsdt',
        noidung: '1',
      });
    } else {
      setPhoneChudat([]);
      setIsPhoneChudat('Nhập mật khẩu sai. Vui lòng nhập lại');
    }
  };

  const checkTotalSo = async () => {
    const checkTotalSo = await countThongKeViewSo({ data: { truong: 'xemso', id_bds: data?.id } });
    const perssion = auth?.permission === 3 ? 30 : auth?.permission === 4 ? 15 : 10000000000;
    if (checkTotalSo?.totalRows < perssion) {
      handleOpen();
      setIsSubModal(2);
      historysUser({
        id_bds: data?.id,
        truong: 'xemso',
        noidung: '1',
      });
    } else if (checkTotalSo?.isOn) {
      handleOpen();
      setIsSubModal(2);
      historysUser({
        id_bds: data?.id,
        truong: 'xemso',
        noidung: '1',
      });
    } else {
      handleOpen();
      setIsSubModal(5);
    }
  };

  const checkTotalSoChu = async () => {
    const checkTotalSo = await countThongKeViewSo({ data: { truong: 'xemsdt', id_bds: data?.id } });
    const perssion = auth?.permission === 3 ? 20 : auth?.permission === 4 ? 7 : 10000000000;
    if (checkTotalSo?.totalRows < perssion) {
      handleOpen();
      setIsSubModal(3);
    } else if (checkTotalSo?.isOn) {
      handleOpen();
      setIsSubModal(3);
    } else {
      handleOpen();
      setIsSubModal(6);
    }
  };

  return (
    <>
      <Dialog open={open} handler={handleOpen} size="lg">
        <div className="flex justify-end -mt-[15px] -mr-[15px]">
          {' '}
          <Button onClick={handleOpen} variant="filled" color="orange" size="sm" className="rounded-xl">
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
        <DialogHeader className="font-[UTM-Avo] justify-center p-1">
          {(isSubModal === 1 || isSubModal === 2) && (
            <div className="text-xl text-center">
              MS #KL{data?.id} -{' '}
              {`${data.loaibds && data?.loaibds}${data.loaivitri && ' - ' + data?.loaivitri}${
                data.duong && ' - đường ' + data?.duong + ', ' + data?.xa + ', ' + data?.huyen
              }`}
            </div>
          )}
          {isSubModal === 3 && (
            <div className="text-lg">
              <span className={`${data.is_user === 2 ? 'text-gray-500' : ''}`}>
                Người nhập: {data.username} {data.is_user === 2 ? '(Đã nghỉ)' : ''} - {data.address}
              </span>
            </div>
          )}
        </DialogHeader>
        <hr className="my-2 border-blue-gray-50" />
        <DialogBody
          className={
            isSubModal === 3 || isSubModal === 2 || isSubModal === 5 || isSubModal === 6
              ? 'overflow-scroll'
              : 'h-[40rem] overflow-scroll'
          }
        >
          <div className="flex flex-col justify-center">
            {isSubModal === 1 && (
              <div>
                <div className="grid justify-center mb-6">
                  <Link to={`/bds/KL${data.id}`}>
                    <div className="text-light-blue-700 hover:underline cursor-pointer">[Bình luận & xem chi tiết]</div>
                  </Link>
                  <div className="flex justify-center">
                    <div>
                      <span
                        className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(2)}
                      >
                        Sổ
                      </span>
                      <span
                        className="bg-orange-400 text-black rounded-md p-2 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(3)}
                      >
                        Xem SĐT
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-blue-gray-50" />
                <PhotoProvider className="!z-[9999]">
                  <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
                    {data.anhto?.split(',').length > 0 &&
                      data.anhto?.split(',').map((imageSrc, i) => (
                        <figure className="relative w-full" key={i}>
                          <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                            <LazyLoadImage
                              alt={'không có ảnh'}
                              width={'100%'}
                              effect="blur"
                              src={`${API_URL}/imgshop?name=${imageSrc}`}
                              className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                              // onClick={() => setFixModal(false)}
                            />
                          </PhotoView>

                          <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i, 'taihinh')}
                            >
                              <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                              <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                  </div>
                </PhotoProvider>
                {data.anhto?.split(',').length > 0 && (
                  <div className="flex justify-center">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhto, 'anhbds', 'taihinh')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {isSubModal === 2 && (
              <div>
                <div className="grid justify-center mb-5">
                  {data.soto?.split(',').length > 0 &&
                    data.soto?.split(',').map((row, i) => (
                      <div key={i} className="text-black">
                        {`Sổ#${i + 1}: `} <span className="font-bold">Tờ:</span> {row} |{' '}
                        <span className="font-bold">Thửa:</span>{' '}
                        {data.sothua?.split(',').length > 0 ? data.sothua?.split(',')[i] : ''} |{' '}
                        <span>
                          {data.xa},{data.huyen}
                        </span>
                      </div>
                    ))}
                </div>
                <PhotoProvider className="!z-[9999]">
                  <div className="grid grid-cols-3 lg:grid-cols-5 gap-2">
                    {data.anhthua?.split(',').length > 0 &&
                      data.anhthua?.split(',').map((imageSrc, i) => (
                        <figure className="relative w-full" key={i}>
                          {typeImg(imageSrc) ? (
                            <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                              <LazyLoadImage
                                alt={'không có ảnh'}
                                width={'100%'}
                                effect="blur"
                                src={`${API_URL}/imgshop?name=${imageSrc}`}
                                className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                              />
                            </PhotoView>
                          ) : (
                            <div className="w-full h-full border rounded-xl flex justify-center items-center">
                              <a
                                href={`${API_URL}/imgshop?name=${imageSrc}`}
                                download={imageSrc}
                                className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-xl"
                              >
                                {'file_' + (i + 1)}
                              </a>
                            </div>
                          )}
                          <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i, 'taiso')}
                            >
                              <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                              <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                            </div>
                          </figcaption>
                        </figure>
                      ))}
                  </div>
                </PhotoProvider>
                {data.anhthua?.split(',').length > 0 && (
                  <div className="flex justify-center mt-3">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhthua, 'sobds', 'taiso')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}
                <hr className="my-2 border-blue-gray-50" />
                <div className="grid justify-center mb-6">
                  <Link to={`/bds/KL${data.id}`}>
                    <div className="text-light-blue-700 hover:underline cursor-pointer">[Bình luận & xem chi tiết]</div>
                  </Link>
                  <div className="flex justify-center">
                    <div>
                      <span
                        className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(1)}
                      >
                        Hình
                      </span>
                      <span
                        className="bg-orange-400 text-black rounded-md p-2 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                        onClick={() => setIsSubModal(3)}
                      >
                        Xem SĐT
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="my-2 border-blue-gray-50" />
              </div>
            )}

            {isSubModal === 3 && (
              <div>
                {phoneChudat.length === 0 && (
                  <div>
                    <div className="text-xl text-black font-bold mb-5">
                      Nhập mật khẩu để xem thông tin chủ BĐS #KL{data.id}
                    </div>
                    <label className="flex items-end gap-1 text-sm mb-1">
                      <b> Nhập Mật khẩu của tài khoản {auth.username}</b>
                    </label>

                    <Input
                      type="password"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      containerProps={{
                        className: 'min-w-[0px!important]',
                      }}
                      onChange={(e) => {
                        setPass(e.target.value);
                      }}
                    />
                    <Button color="orange" size="sm" className="rounded-full mt-4" onClick={checkInfoChuDat}>
                      Xem thông tin
                    </Button>
                  </div>
                )}
                {phoneChudat.length > 0 && (
                  <div className="grid justify-center">
                    <div className="text-xl text-center text-black mb-3">
                      <span className="font-bold font-[UTM-Avo]">Thông tin chủ BĐS #KL{data.id}</span>
                    </div>

                    {phoneChudat.map((row, i) => (
                      <div key={i} className="text-black">
                        {`Sđt #${i + 1}: `} <span className="font-bold">{row}</span>
                      </div>
                    ))}

                    {data?.ishanche === 1 && (
                      <div>
                        <span className="text-red-500 font-bold">
                          Lưu ý: Cần việc quan trọng mới gọi chủ đất - hạn chế gọi nhiều{' '}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {phoneChudat.length > 0 && (
                  <div>
                    <hr className="my-2 border-blue-gray-50" />

                    <div className="grid justify-center mb-6">
                      <Link to={`/bds/KL${data.id}`}>
                        <div className="text-light-blue-700 hover:underline cursor-pointer">
                          [Bình luận & xem chi tiết]
                        </div>
                      </Link>
                      <div className="flex justify-center">
                        <div>
                          <span
                            className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                            onClick={() => setIsSubModal(1)}
                          >
                            Hình
                          </span>
                          <span
                            className="text-xs text-center text-black bg-orange-400 rounded-md p-2 font-bold mx-1 cursor-pointer hover:bg-green-300"
                            onClick={() => setIsSubModal(2)}
                          >
                            Sổ
                          </span>
                        </div>
                      </div>
                    </div>
                    <hr className="my-2 border-blue-gray-50" />
                  </div>
                )}
                <div>{isPhoneChudat ? <b className="text-red-500">{isPhoneChudat}</b> : ''}</div>
              </div>
            )}

            {isSubModal === 4 && (
              <div>
                <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 mt-5 ">
                  {data.anhto?.split(',').length > 0 &&
                    data.anhto?.split(',').map((imageSrc, i) => (
                      <figure className="relative w-full" key={i}>
                        <img
                          src={`${API_URL}/imgshop?name=${imageSrc}`}
                          alt="not fount"
                          width={'100%'}
                          className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl"
                          loading="lazy"
                        />
                        <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i)}
                          >
                            <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                            <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                          </div>
                        </figcaption>
                      </figure>
                    ))}
                </div>
                {data.anhto?.split(',').length > 0 && (
                  <div className="flex justify-center">
                    <div
                      className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                      onClick={() => downloadImagesAsZip(data.anhto, 'anhbds')}
                    >
                      <ArrowDownTrayIcon className="w-5 h-5" />
                      <span className="ml-1">Tải về tất cả</span>
                    </div>
                  </div>
                )}

                <div className="mt-4 leading-7 text-black">
                  <p className="font-bold">{data?.tieude}</p>
                  <p className=" font-sans">
                    {' '}
                    {(data.sotang ? '👉 ' + data?.sotang + ' tầng' : '') +
                      (data.sotang
                        ? ' - ngang ' + data?.ngang + 'm' + (data?.nganghau && `(ngang hậu ${data?.nganghau}m)`)
                        : data?.ngang
                        ? '👉 ngang' + data?.ngang + 'm' + (data?.nganghau && `(ngang hậu ${data?.nganghau}m)`)
                        : '') +
                      (data.sophong ? ' - ' + data?.sophong + ' phòng' : '') +
                      (data.sowc ? ' - ' + data?.sowc + ' WC' : '') +
                      (data.dientich && data.dai
                        ? ` - ${data.ngang ? data.ngang + 'm x' : ''} ${data.dai ? data.dai + 'm' : ''} - ${
                            data.dientich
                          }m2`
                        : ` ${data.dientich ? ' - DT ' + data.dientich + 'm2' : ''}`) +
                      (data.dientichdatnn ? ' - ' + data?.dientichdatnn + ' m2 đất nông nghiệp' : '') +
                      (data.dientichsan ? ' - tổng diện tích sàn ' + data?.dientichsan + 'm2' : '') +
                      (data.huongbds ? ' - hướng ' + data?.huongbds : '') +
                      (data.tiennghi ? ' - ' + data?.tiennghi : '') +
                      (data.dactrung ? ' - ' + data?.dactrung : '') +
                      (data.quyhoach ? ' - Quy hoạch ' + data?.quyhoach : '')}
                  </p>
                  <div
                    className=" font-sans"
                    dangerouslySetInnerHTML={{
                      __html: data?.notekhach ? data?.notekhach.replace(/\n/g, '<br />') : '',
                    }}
                  />

                  <div className="mt-5">
                    {data.status === '2' ? (
                      <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
                    ) : (
                      <p className="font-bold text-green-700 font-sans ml-1">
                        {convertNumberToWords(data?.gia ? data?.gia : data?.giathue)} -{' '}
                        {data?.gia && data?.dientich
                          ? `${convertNumberToWords(parseInt(data?.gia) / parseInt(data?.dientich))}/m2`
                          : ''}{' '}
                        <span className="font-thin text-black">- MS: #KL{data?.id}</span>
                      </p>
                    )}
                  </div>
                </div>
                <hr className="my-2 border-blue-gray-50" />
                <div>
                  {data?.notenoibo && <span className="text-black">👉 Ghi chú (nội bộ): {data?.notenoibo}</span>}
                </div>
                <div className="flex mt-2">
                  <DialogBds data={data} />
                </div>
                <hr className="my-2 border-blue-gray-50" />
                <div className="mt-10 text-center">
                  <b>
                    👉 Nhập bởi:{' '}
                    <b className={`${data.is_user === 2 ? 'text-gray-500' : ''}`}>
                      {data.is_user === 2 ? 'ĐÂ NGHỈ' : ''} {data.name} - {data.username} - {data.address}
                    </b>
                  </b>
                </div>
              </div>
            )}

            {isSubModal === 5 && (
              <div className="grid justify-items-center  items-center">
                <BellAlertIcon className="h-20 w-20 text-red-400" />
                <span className="font-bold text-red-400">
                  BẠN ĐÃ HẾT SỐ LƯỢNG XEM SỔ MỚI TRONG NGÀY{' '}
                  {auth?.permission === 3 ? '30/30' : auth?.permission === 4 ? '15/15' : ''}
                </span>
              </div>
            )}

            {isSubModal === 6 && (
              <div className="grid justify-items-center  items-center">
                <BellAlertIcon className="h-20 w-20 text-red-400" />
                <span className="font-bold text-red-400">
                  BẠN ĐÃ HẾT SỐ LƯỢNG XEM SỐ CHỦ MỚI TRONG NGÀY{' '}
                  {auth?.permission === 3 ? '20/20' : auth?.permission === 4 ? '7/7' : ''}
                </span>
              </div>
            )}
          </div>
        </DialogBody>
      </Dialog>
      {Object.keys(data).length > 0 && (
        <div className="grid grid-cols-5 gap-2 rounded-[40px] border-t-2 border-t-amber-500 p-4 items-center hover:bg-[#fffdf4]">
          <div>
            <LazyLoadImage
              alt={'không có ảnh'}
              width={'100%'}
              effect="blur"
              src={imageAvt}
              onError={(e) => {
                e.target.src = images.banner_login;
              }}
              className="rounded-3xl w-96"
            />
          </div>

          <div className="col-span-4 leading-7">
            <Link to={`/bds/KL${data.id}`}>
              <h2 className="text-base font-bold mb-1 cursor-pointer">{`${data.loaibds ? data?.loaibds : ''}${
                data.loaivitri ? ' - ' + data?.loaivitri : ''
              }${data.phaply ? ' - ' + data?.phaply : ''}${
                data.duong ? ' - đường ' + data?.duong + ', ' + data?.xa + ', ' + data?.huyen : ''
              }${data.rongduong ? ', đường trước nhà ' + data?.rongduong + 'm' : ''}`}</h2>
            </Link>
            <p className=" font-sans">
              {' '}
              {(data.sotang ? '👉 ' + data?.sotang + ' tầng' : '') +
                (data.sotang
                  ? (data?.ngang ? ' - ngang ' + data?.ngang + 'm' : '') +
                    (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                  : data?.ngang
                  ? (data?.ngang ? '👉 ngang' + data?.ngang + 'm' : '') +
                    (data?.nganghau ? `(ngang hậu ${data?.nganghau}m)` : '')
                  : '') +
                (data.sophong ? ' - ' + data?.sophong + ' phòng' : '') +
                (data.sowc ? ' - ' + data?.sowc + ' WC' : '') +
                (data.dientich && data.dai
                  ? ` - ${data.ngang ? data.ngang + 'm x' : ''} ${data.dai ? data.dai + 'm' : ''} - ${data.dientich}m2`
                  : `  ${data.dientich ? ' - DT ' + data.dientich + 'm2' : ''}`) +
                (data.dientichdatnn ? ' - ' + data?.dientichdatnn + ' m2 đất nông nghiệp' : '') +
                (data.dientichsan ? ' - tổng diện tích sàn ' + data?.dientichsan + 'm2' : '') +
                (data.huongbds ? ' - hướng ' + data?.huongbds : '') +
                (data.tiennghi ? ' - ' + data?.tiennghi : '') +
                (data.dactrung ? ' - ' + data?.dactrung : '') +
                (data.quyhoach ? ' - Quy hoạch ' + data?.quyhoach : '')}
            </p>
            <div
              className=" font-sans"
              dangerouslySetInnerHTML={{
                __html: data?.notekhach ? data?.notekhach.replace(/\n/g, '<br />') : '',
              }}
            />
            <div className="flex items-center my-1 lg:px-5 font-sans">
              <div className="flex items-center mr-2">
                <UserIcon className="w-5 h-5 mr-1" />{' '}
                <label className={`${data?.is_user === 2 ? 'text-gray-400' : ''}`}>{data?.username}</label>{' '}
              </div>
              <div className="flex items-center">
                <ClockIcon className="w-5 h-5 mr-1" />{' '}
                <label>
                  {data?.update_date?.split('T')[1]} - {data?.update_date?.split('T')[0]}
                </label>{' '}
              </div>
            </div>

            <div>
              {data.status === '2' ? (
                <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
              ) : (
                <p className="font-bold text-green-700 font-sans ml-1">
                  {convertNumberToWords(data?.gia ? data?.gia : data?.giathue)}{' '}
                  {data?.gia && data?.dientich
                    ? `- ${convertNumberToWords(parseInt(data?.gia) / parseInt(data?.dientich))}/m2`
                    : ''}
                </p>
              )}
            </div>

            <hr className="my-2 border-blue-gray-50" />
            <div className="flex items-end justify-end">
              <div>
                <span className="mx-1">
                  {data?.isdocquyen === 1 ? (
                    <b>Độc quyền</b>
                  ) : data?.isdocquyen === 2 ? (
                    <b className="text-red-500">Hàng hot</b>
                  ) : (
                    ''
                  )}
                  {data?.ishanche === 2 ? <b> - Hạn chế</b> : ''}
                </span>
                <span className="mx-1 flex lg:inline">
                  +{data?.create_date?.split('T')[1]} - {data?.create_date?.split('T')[0]}
                </span>
                <span className="mx-1">#KL{data?.id}</span>
                <span
                  className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={() => {
                    handleOpen();
                    setIsSubModal(1);
                    historysUser({
                      id_bds: data?.id,
                      truong: 'xemhinh',
                      noidung: '1',
                    });
                  }}
                >
                  Hình
                </span>
                <span
                  className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={checkTotalSo}
                >
                  Sổ
                </span>
                <span className="bg-orange-400 rounded-md p-1 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300">
                  Chia sẻ
                </span>
                {data?.ishanche === 2 && (auth?.permission === 1 || auth?.permission === 2) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span> //|| auth?.username === data?.username
                ) : data?.isdocquyen === 1 &&
                  (auth?.permission === 1 || auth?.permission === 2 || auth?.username === data?.username) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span>
                ) : (data?.ishanche === 0 || data?.ishanche === 1) &&
                  (data?.isdocquyen === 0 || data?.isdocquyen === 2) ? (
                  <span
                    className="text-xs text-center bg-orange-400 rounded-md p-1 font-bold mx-1 cursor-pointer hover:bg-green-300"
                    onClick={checkTotalSoChu}
                  >
                    SĐT
                  </span>
                ) : (
                  ''
                )}
                <span
                  className="bg-orange-400 rounded-md p-1 whitespace-nowrap text-xs font-bold mx-1 cursor-pointer hover:bg-green-300"
                  onClick={() => {
                    handleOpen();
                    setIsSubModal(4);
                    historysUser({
                      id_bds: data?.id,
                      truong: 'xemchitiet',
                      noidung: '1',
                    });
                  }}
                >
                  Bình luận
                </span>
              </div>
              <div>
                <HeartIcon className="w-5 h-5" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FrameShowBds;
