import { get, getSV, postSV, postUpload } from '../utils/https';

export const addBds = async (formData) => {
  try {
    const res = await postUpload(`/addbds`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const historyBds = async (data) => {
  try {
    const res = await postSV(`/hisbds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const historyUser = async (data) => {
  try {
    const res = await postSV(`/hisuser`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const uploadFileSim = async (data) => {
  try {
    const res = await postUpload(`/uploadFileBds`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateBds = async (formData) => {
  try {
    const res = await postUpload(`/updatebds`, formData);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getIdBds = async (id) => {
  try {
    const res = await get(`/getidbds?id=${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAllUser = async (page, stt, username, masp) => {
  let userName = username ? `&username=${username}` : '';
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listbdsuser?page=${page}${isStatus}${userName}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllCountUser = async (stt, username, masp) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  try {
    const res = await getSV(`/listbdsusercount?username=${username}${isStatus}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDAll = async (page, stt, masp) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let size = 50;
  try {
    const res = await getSV(`/listbdsall?page=${page}${isStatus}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDShow = async (page, stt, masp, text, loaibds, xa) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let size = '&size=2';
  try {
    const res = await getSV(`/listbdsshow?page=${page}${isStatus}${maSP}${textSP}${loaibdsSP}${xaSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllShowCount = async (stt, masp, text, loaibds, xa) => {
  let maSP = masp ? `&code=${masp}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  try {
    const res = await getSV(`/listbdsallshowcount?is=${stt}${maSP}${textSP}${loaibdsSP}${xaSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllCount = async (stt, masp) => {
  let maSP = masp ? `&code=${masp}` : '';
  try {
    const res = await getSV(`/listbdsallcount?is=${stt}${maSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDShowDaxemDetail = async (page, stt, username, masp, text, loaibds, xa) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let size = '&size=2';
  try {
    const res = await getSV(`/listbdssdaxem?page=${page}${isStatus}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductIDShowDaxem = async (page, stt, username, masp, text, loaibds, xa) => {
  let isStatus = stt ? `&is=${stt}` : '';
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  let size = '&size=20';
  try {
    const res = await getSV(
      `/listbdssdaxem?page=${page}${size}${isStatus}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}`,
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const listProductIDAllDaxemCount = async (stt, username, masp, text, loaibds, xa) => {
  let maSP = masp ? `&code=${masp}` : '';
  let userName = username ? `&username=${username}` : '';
  let textSP = text ? `&q=${text}` : '';
  let loaibdsSP = loaibds ? `&loaibds=${loaibds}` : '';
  let xaSP = xa ? `&xa=${xa}` : '';
  try {
    const res = await getSV(`/listbdsdaxemcount?is=${stt}${userName}${maSP}${textSP}${loaibdsSP}${xaSP}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const listProductTodayCount = async () => {
  try {
    const res = await getSV(`/listbdstodaycount`);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const checkPhoneChuDat = async (data) => {
  try {
    const res = await postSV(`/checkphonedat`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const countThongKeViewSo = async (data) => {
  try {
    const res = await postSV(`/totaltkview`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};
