import { ArrowDownTrayIcon, HeartIcon } from '@heroicons/react/24/outline';
import { listProductIDShow } from '../../../services/apiBds';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { convertNumberToWords, laySoTuDoanText } from '../../../utils/helper';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { API_URL } from '../../../utils/urls';
import { MinusIcon } from '@heroicons/react/24/solid';
import { Input, Textarea } from '@material-tailwind/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import DialogBds from '../../../components/DialogBds';
import { historysUser } from '../../../utils/history';
import LoadingFrame from '../../../components/loadingFrame';

export default function BdsDetail() {
  const params = useParams();
  const [resultListBds, setResultListBds] = useState({});
  const [loading, setLoading] = useState(false);
  const listBdsAll = async (pages = 1, code = '') => {
    const resultBDSAll = await listProductIDShow(pages, '1', code);
    if (resultBDSAll.length > 0) {
      setResultListBds(resultBDSAll[0]);
      historysUser({
        id_bds: laySoTuDoanText(params.id),
        truong: 'xemchitiet',
        noidung: '1',
      });
      setLoading(false);
    } else {
      setResultListBds([]);
      setLoading(true);
    }
  };
  useEffect(() => {
    if (params.id) {
      listBdsAll(1, laySoTuDoanText(params.id));
    }
  }, []);

  const downloadImagesAsZip = (arrImage, namezip) => {
    const zip = new JSZip();
    const imageUrls = arrImage?.split(',').length > 0 ? arrImage?.split(',') : [];

    const promises = imageUrls.map((imageUrl, index) => {
      return fetch(`${API_URL}/imgshop?name=${imageUrl}`)
        .then((response) => response.blob())
        .then((blob) => {
          zip.file(imageUrl, blob);
        });
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, namezip + '.zip');
      });
    });
  };

  const downloadImage = (imageURL, index) => {
    saveAs(imageURL, `${index + 1}_${Date.now()}`);
  };

  return (
    <div className="container mx-auto">
      {Object.keys(resultListBds).length > 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-7 gap-4">
          <div className="lg:col-span-5">
            <div className="flex gap-4">
              {' '}
              <div className="flex items-center text-pink-600 font-bold">
                <HeartIcon className="h-5 w-5" />
                Thích
              </div>
              <div className="font-bold">
                Nhập lúc {resultListBds?.update_date?.split('T')[1]} - {resultListBds?.update_date?.split('T')[0]}
              </div>
              <div className="font-bold">MS: #KL{resultListBds?.id}</div>
            </div>

            <PhotoProvider className="!z-[9999]">
              <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 mt-5 ">
                {resultListBds.anhto?.split(',').length > 0 &&
                  resultListBds.anhto?.split(',').map((imageSrc, i) => (
                    <figure className="relative w-full" key={i}>
                      <PhotoView src={`${API_URL}/imgshop?name=${imageSrc}`}>
                        <LazyLoadImage
                          alt={'không có ảnh'}
                          width={'100%'}
                          effect="blur"
                          src={`${API_URL}/imgshop?name=${imageSrc}`}
                          className=" border hover:border-amber-500 hover:border-dashed p-1 rounded-2xl cursor-pointer"
                          // onClick={() => setFixModal(false)}
                        />
                      </PhotoView>
                      <figcaption className="absolute top-[5px] left-[29px] flex px-1 -translate-x-2/4 justify-center border border-white bg-white text-white shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm rounded-lg">
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => downloadImage(`${API_URL}/imgshop?name=${imageSrc}`, i)}
                        >
                          <ArrowDownTrayIcon className="w-5 h-5 text-orange-600" />
                          <span className="text-orange-600 text-xs ml-1"> #{i + 1}</span>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
              </div>
            </PhotoProvider>
            {resultListBds.anhto?.split(',').length > 0 && (
              <div className="flex justify-center">
                <div
                  className="flex items-center cursor-pointer p-2 bg-orange-500 text-gray-900 font-bold rounded-xl hover:bg-green-300"
                  onClick={() => downloadImagesAsZip(resultListBds.anhto, 'anhbds')}
                >
                  <ArrowDownTrayIcon className="w-5 h-5" />
                  <span className="ml-1">Tải về tất cả</span>
                </div>
              </div>
            )}

            <div className="mt-4 leading-7">
              <p className="font-bold">{resultListBds?.tieude}</p>
              <p className=" font-sans">
                {' '}
                {(resultListBds.sotang ? '👉 ' + resultListBds?.sotang + ' tầng' : '') +
                  (resultListBds.sotang
                    ? ' - ngang ' +
                      resultListBds?.ngang +
                      'm' +
                      (resultListBds?.nganghau && `(ngang hậu ${resultListBds?.nganghau}m)`)
                    : resultListBds?.ngang
                    ? '👉 ngang' +
                      resultListBds?.ngang +
                      'm' +
                      (resultListBds?.nganghau && `(ngang hậu ${resultListBds?.nganghau}m)`)
                    : '') +
                  (resultListBds.sophong ? ' - ' + resultListBds?.sophong + ' phòng' : '') +
                  (resultListBds.sowc ? ' - ' + resultListBds?.sowc + ' WC' : '') +
                  (resultListBds.dientich && resultListBds.dai
                    ? ` - ${resultListBds.ngang ? resultListBds.ngang + 'm x' : ''} ${
                        resultListBds.dai ? resultListBds.dai + 'm' : ''
                      } - ${resultListBds.dientich}m2`
                    : ` - ${resultListBds.dientich ? 'DT ' + resultListBds.dientich + 'm2' : ''}`) +
                  (resultListBds.dientichdatnn ? ' - ' + resultListBds?.dientichdatnn + ' m2 đất nông nghiệp' : '') +
                  (resultListBds.dientichsan ? ' - tổng diện tích sàn ' + resultListBds?.dientichsan + 'm2' : '') +
                  (resultListBds.huongbds ? ' - hướng ' + resultListBds?.huongbds : '') +
                  (resultListBds.tiennghi ? ' - ' + resultListBds?.tiennghi : '') +
                  (resultListBds.dactrung ? ' - ' + resultListBds?.dactrung : '') +
                  (resultListBds.quyhoach ? ' - Quy hoạch ' + resultListBds?.quyhoach : '')}
              </p>
              <div
                className=" font-sans"
                dangerouslySetInnerHTML={{
                  __html: resultListBds?.notekhach ? resultListBds?.notekhach.replace(/\n/g, '<br />') : '',
                }}
              />

              <div className="mt-5">
                {resultListBds.status === '2' ? (
                  <p className="font-bold text-red-700 font-sans ml-1">Đã Bán</p>
                ) : (
                  <p className="font-bold text-green-700 font-sans ml-1">
                    {convertNumberToWords(resultListBds?.gia ? resultListBds?.gia : resultListBds?.giathue)}{' '}
                    {resultListBds?.gia && resultListBds?.dientich
                      ? `- ${convertNumberToWords(parseInt(resultListBds?.gia) / parseInt(resultListBds?.dientich))}/m2`
                      : ''}{' '}
                    <span className="font-thin text-black">- MS: #KL{resultListBds?.id}</span>
                  </p>
                )}
              </div>
            </div>
            <hr className="my-2 border-blue-gray-50" />
            <div>
              {resultListBds?.notenoibo && (
                <span className="text-black">👉 Ghi chú (nội bộ): {resultListBds?.notenoibo}</span>
              )}
            </div>
            <div className="flex mt-2">
              <DialogBds data={resultListBds} />
            </div>
            <hr className="my-2 border-blue-gray-50" />
            <div className="mt-10 text-center">
              <b>
                👉 Nhập bởi:{' '}
                <b className={`${resultListBds.is_user === 2 ? 'text-gray-500' : ''}`}>
                  {resultListBds.is_user === 2 ? 'ĐÂ NGHỈ' : ''} {resultListBds.name} - {resultListBds.username} -{' '}
                  {resultListBds.address}
                </b>
              </b>
            </div>
          </div>

          <div className="lg:col-span-2 bg-white rounded-2xl">
            <div
              className="border-[6px] border-gray-100 rounded-2xl"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.26) 0px 5px 15px' }}
            >
              <div className="border border-dashed border-orange-500 rounded-2xl">
                <div className="flex items-center bg-orange-400 rounded-2xl p-2 font-bold">
                  <MinusIcon className="h-5 w-5" /> Bình luận công khai
                </div>
                <div className="bg-white mt-2 p-2 rounded-2xl">
                  <div className="grid grid-cols-3 gap-2">
                    <div className="p-1 bg-red-500 text-white rounded-xl text-xs text-center">Đã bán?</div>
                    <div className="p-1 bg-orange-500 text-white rounded-xl text-xs text-center">Ngừng bán?</div>
                    <div className="p-1 bg-black text-white rounded-xl text-xs text-center">Không hề bán?</div>
                    <div className="p-1 bg-green-500 text-white rounded-xl text-xs text-center">Còn bán?</div>
                  </div>

                  <div>
                    <label className="flex items-end gap-1 text-xs mb-1 mt-5">
                      <b>Giá Bán Mới (chỉ nhập số - nếu có)</b>
                    </label>
                    <Input
                      //   value={arrBDS?.sonha}
                      placeholder="chỉ nhập số "
                      className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      // onChange={(e) => {
                      //   setArrBDS({ ...arrBDS, sonha: e.target.value });
                      // }}
                    />
                    <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                      <b>Nội dung (Không nhập SĐT chủ ở đây)</b>
                    </label>
                    <Textarea
                      //   value={arrBDS?.notenoibo}
                      type="text"
                      className="!border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      //   onChange={(e) => {
                      //     setArrBDS({ ...arrBDS, notenoibo: e.target.value });
                      //   }}
                    />
                    <div className="text-[10px] font-bold">SĐT bên Chủ thì nhập riêng vào ô SĐT bên dưới</div>
                    <div className="text-[10px] font-bold">Có thể nhấn Ctrl+v để Dán nội dung đã Copy</div>
                    <label className="flex items-end gap-1 text-xs mb-1 mt-3">
                      <b>Nhập SĐT kèm Tên Chủ mới</b>
                    </label>
                    <Input
                      //   value={arrBDS?.sonha}
                      placeholder="VD: 0912345678 Viet Nam "
                      className="w-full rounded-xl !border !border-gray-400 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100"
                      labelProps={{
                        className: 'hidden',
                      }}
                      // onChange={(e) => {
                      //   setArrBDS({ ...arrBDS, sonha: e.target.value });
                      // }}
                    />
                    <div className="text-[10px] font-bold">Theo mẫu: 0912345678 Viet Nam</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="flex justify-center">
          <b>BÀI VIẾT KHÔNG TỒN TẠI</b>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
          <div className="lg:col-span-3">
            <LoadingFrame />
          </div>
        </div>
      )}
    </div>
  );
}
